@import 'common';

.data-landing-page {
  @include mobile {
    padding: 100px 15px;
  }

  h1, h2, p {
    @include mobile {
      text-align: center;
    }
    @include desktop {
      text-align: left;
    }
  }
  h3 {

    margin: 0 0 25px; 
    padding: 0;
    @include desktop {
      text-align: left;
    }
    @include mobile {
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.01em;
      font-size: 28px;
    }
  }
  button {
    @include mobile {
      align-self: center;
      width: 100%;
    }
  }


  .trusted-section {
    position: absolute;
    width: 100vw;
    left: 0;
    top: 725px;
    background: #353760;
    min-height: 280px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mobile {
      position: static;
      width: 100%;
      min-height: initial;
      justify-content: flex-start;
      padding: 10px 0 40px;
      margin: 35px 0;
    }

    .title-container {
      width: 100%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      justify-content: center;
      color: #f0f0f0;
      font-weight: 600;
      font-size: 20px;
      margin-top: -40px;
      margin-bottom: 50px;

      @include mobile {
        margin-top: 20px;
        margin-bottom: 15px;
        font-size: 16px;
      }
    }

    .logos-container {
      display: grid;
      
      justify-content: center;
      align-items: center;
      
      @include desktop {
        padding: 0 100px;
        grid-template-columns: repeat(4, 1fr);
      }
      @include mobile {
        display: flex;
        flex-wrap: wrap;
        min-height: initial;
      }

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        @include desktop {
          height: 40px;
        }

        @include mobile {
          width: 40%;
        }
      }

      @include mobile {
        padding: 0 20px 0;
      }
    }
  }

  .mid-section {
    @include desktop {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
    }
    > .right {
      @include desktop {
        width: 55%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    .mid-section-item {
        -webkit-text-size-adjust: 100%;
        font-size: 14px;
        line-height: 20px;
        font-family: Poppins, sans-serif;
        box-sizing: border-box;
        font-weight: 600;
        max-width: 100%;
        display: inline-block;
        position: relative;
        width: 100%;
        margin-bottom: 35px;
        padding: 25px;
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 7px 7px 55px 0 #f2f3f6;
        color: #44465f;
        text-decoration: none;
        min-height: 150px;
        padding-top: 25px;
        padding-right: 10px;
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        &:first-child {
          @include desktop {
            margin-top: -80px;
          margin-bottom: 90px;
          }
        }
        &:nth-child(3) {
          @include desktop {
            margin-top: -60px;
          margin-bottom: 70px;
          }
        }

        @include desktop {
          max-width: 312px;
          margin-left: 35px;
        }

        .title {
          @include mobile {
            font-weight: 500;
            text-align: center;
            padding: 0 10%;
          }
        }
      }
    }
    > .left {
      @include desktop {
        width: 45%;
        @include desktop {
          margin-top: 100px;
        }
      }

    }


  }

  .cta-btn {
    max-width: 200px;
  }
  .bottom-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      margin: 0 0 50px;
      padding: 0 !important;
      max-width: initial;
      text-align: center;
      font-weight: normal;
      max-width: 800px;
      font-size: 32px;
      line-height: 40px;
      @include mobile {
        font-size: 25px;
        line-height: 32px;
      }
    
    }
    .cta-btn-bottom {
      max-width: 300px;
      width: 100%;
    }
  }

  .how-it-works {
    img {
      height: fit-content !important;
    }
  }

  .contact {
    display: flex;
    justify-content: center;
    margin: 100px 0;

    .right {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    p {
      @include mobile {
        text-align: center;
      }
    }

    a {
      color: #353760;
      @include mobile {
        font-size: 17px;
      }
      @include desktop {
        white-space: nowrap;
      }
      &:hover {
         text-decoration: underline;
      }
    }
  }

  .center-section {
    @include mobile {
      text-align: center;
    }
    .description {
      @include mobile {
        text-align: center;
      }
    }
  }

  .magazine-section {
    margin-top: 50px;

    .magazine-articles-container {
      margin-top: 50px;
      display: flex;
      width: 100%;
      column-gap: 50px;

      @include mobile {
        flex-direction: column;
        row-gap: 25px;
      }

      > a {

      }

      .magazine-article-card {
        border-radius: 15px;
        background-color: #fff;
        box-shadow: 7px 7px 55px 0 #f2f3f6;
        width: 35%;
        cursor: pointer;
        transition: background-color 0.3s ease, filter 0.3s ease;

        @include mobile {
          width: 100%;
        }

        &:hover {
          background-color: #f5f5f5;
        }

        > img {
          width: 100%;
          height: 250px;
          object-fit: cover;

          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }

        .magazine-article-content-container {
          padding: 20px;
          min-height: 200px;
          > p {
            color: #333739;
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
