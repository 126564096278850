@import "common";

.featurePicker {
    @include desktop {
        margin-top: 50px;
    }

    .featurePickerTitle {
        @include desktop {
            text-align: center;
            margin: 0 auto;
            max-width: 800px;
            margin-bottom: 35px;
        }
    }
    > p {
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 100px;
    }
    > .inner {
        display: flex;
        justify-content: space-between;
    
        @include mobile {
            flex-direction: column;
        }

        >.left {
            @include desktop {
                width: 29%;
            }
            @include mobile {
                display: flex;
                flex-wrap: wrap;
            }
    
            .feature {
                font-size: 16px;
                padding: 15px 0;
                border-radius: 15px;
                cursor: pointer;
                // background-color: #f5f5f5;
                transition: padding 0.5s ease;
                user-select: none;
                @include mobile {
                    white-space: nowrap;
                    padding: 5px 8px;
                    border: 1px solid #e7e7e7;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 4px;
                    margin-bottom: 10px;
                    border-radius: 10px;
                }
                &:hover,
                &.selected {
                    @include desktop {
                        padding: 15px 25px;
                        font-weight: 600;
                    }
                    
                    background-color: #f5f5f5;
                    
                }
            }
            h5 {
                @include mobile {
                    display: none;
                }
            }
        }
    
        >.view {
            // background: #f2f2f2;
            box-shadow: 7px 7px 55px 0 #f2f3f6;
    
            @include desktop {
                width: 70%;
                height: auto;
                align-self: flex-start;
            }
    
            >.header {
                padding: 25px 25px 25px;
    
                h4 {
                    text-align: left;
                    padding: 0;
                    margin: 0;
                    font-size: 23px;
                    font-weight: 500;
                }
    
            }
    
            img {
                width: 100%;
            }
        }
    }


}